import type {
  SVGImageProps,
  SVGLineProps,
  SVGRectProps,
  SVGTextProps,
} from '@mathflat/visual-editor'
import { orderBy } from 'lodash'

import type { DesignTemplateResponse } from '../api'
import { COVER_DATA_TYPE, type CoverDataType } from '../constant'

export class CoverData<PreAssignedCoverData extends Record<string, CoverDataItem | null>> {
  // api response는 편의를 위해 coverData에 필수값+커스텀값 다 담겨 오고,
  // client에서는 필수값과 커스텀값을 나눠서 사용하는게 편하기 때문에 여기 분리
  // 이 dto의 constructor에서 api response받아도 되고, 자기자신도 받을 수 있도록 분기
  preAssignedCoverData: MappedCoverData<PreAssignedCoverData>
  customCoverData: CoverDataItem[]

  width?: number
  height?: number

  constructor(
    data:
      | DesignTemplateResponse<PreAssignedCoverData>['coverData']
      | CoverData<PreAssignedCoverData>,
  ) {
    if (data instanceof CoverData) {
      this.preAssignedCoverData = data.preAssignedCoverData
      this.customCoverData = data.customCoverData
      this.width = data.width
      this.height = data.height
    } else {
      const { customData, width, height, ...requiredCoverData } = data
      const coverData = {}
      if (width) {
        this.width = Number(width)
      }
      if (height) {
        this.height = Number(height)
      }

      Object.entries(requiredCoverData).forEach(([key, value]) => {
        if (value === null) {
          coverData[key] = null
        } else {
          coverData[key] = createCoverDataItem(value)
        }
      })
      this.preAssignedCoverData =
        coverData as CoverData<PreAssignedCoverData>['preAssignedCoverData']

      this.customCoverData = customData.map((customCoverDataItem) =>
        createCoverDataItem(customCoverDataItem),
      )
    }
  }

  getItem: {
    <K extends keyof MappedCoverData<PreAssignedCoverData> | CoverDataItem['id']>(
      key: K,
    ): K extends keyof MappedCoverData<PreAssignedCoverData>
      ? MappedCoverData<PreAssignedCoverData>[K]
      : CoverDataItem | undefined
  } = <K extends keyof MappedCoverData<PreAssignedCoverData> | CoverDataItem['id']>(key) => {
    if (key in this.preAssignedCoverData) {
      return this.preAssignedCoverData[
        key as keyof MappedCoverData<PreAssignedCoverData>
      ] as K extends keyof MappedCoverData<PreAssignedCoverData>
        ? MappedCoverData<PreAssignedCoverData>[K]
        : CoverDataItem | undefined
    } else {
      return this.customCoverData.find(
        (v) => v.id === key,
      ) as K extends keyof MappedCoverData<PreAssignedCoverData>
        ? MappedCoverData<PreAssignedCoverData>[K]
        : CoverDataItem | undefined
    }
  }

  get allItems() {
    return toAllOrderedFilteredCoverItems({
      coverData: this.preAssignedCoverData,
      customCoverData: this.customCoverData,
    })
  }

  getPayload = () => {
    if (!this.width || !this.height) {
      throw Error('필수 값입니다.')
    }

    return {
      ...this.preAssignedCoverData,
      customData: this.customCoverData,
      width: String(this.width),
      height: String(this.height),
    }
  }
}

// TODO:교재 타입에 맞게 생성하도록 수정하기
function createCoverDataItem(data: CoverDataItem) {
  switch (data.type) {
    case COVER_DATA_TYPE.TEXT:
      return new TextCoverDataItem(data)
    case COVER_DATA_TYPE.IMAGE:
      return new ImageCoverDataItem(data)
    case COVER_DATA_TYPE.RECT:
      return new RectCoverDataItem(data)
    case COVER_DATA_TYPE.LINE:
      return new LineCoverDataItem(data)
  }
}

// 커버데이터의 한 아이템은 이렇게 생겼습니다. 이걸 사용한 곳에서는 coverDataItem이란 느낌의 이름을 가집니다.
export type CoverDataItem<
  T extends CoverDataType = CoverDataType,
  K extends string = string,
> = T extends typeof COVER_DATA_TYPE.TEXT
  ? TextCoverDataItem<K>
  : T extends typeof COVER_DATA_TYPE.IMAGE
    ? ImageCoverDataItem<K>
    : T extends typeof COVER_DATA_TYPE.LINE
      ? LineCoverDataItem<K>
      : RectCoverDataItem<K>

export class TextCoverDataItem<K extends string = string> {
  type: typeof COVER_DATA_TYPE.TEXT
  props: SVGTextProps
  id: K
  order: number

  constructor(data: TextCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
}

export class ImageCoverDataItem<K extends string = string> {
  type: typeof COVER_DATA_TYPE.IMAGE
  props: SVGImageProps
  id: K
  order: number

  constructor(data: ImageCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
}
export class RectCoverDataItem<K extends string = string> {
  type: typeof COVER_DATA_TYPE.RECT
  props: SVGRectProps
  id: K
  order: number

  constructor(data: RectCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
}

export class LineCoverDataItem<K extends string = string> {
  type: typeof COVER_DATA_TYPE.LINE
  props: SVGLineProps
  id: K
  order: number

  constructor(data: LineCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
}

export const toAllOrderedFilteredCoverItems = ({
  coverData,
  customCoverData,
}: {
  coverData: Record<string, CoverDataItem | null>
  customCoverData: CoverDataItem[]
}) => {
  return orderBy(
    [...(customCoverData ?? []), Object.values(coverData ?? [])].flat(),
    'order',
  ).filter((v) => !!v) as CoverDataItem[]
}

export type MappedCoverData<T extends Record<string, CoverDataItem | null>> = {
  [K in keyof T]: T[K] extends CoverDataItem
    ? T[K]['type'] extends infer CT
      ? CT extends CoverDataType
        ? CoverDataItem<CT, K & string>
        : never
      : never
    : T[K]
}

export const COVER_BINDING_AREA_RECT_ID = 'COVER_BINDING_AREA'
export const COVER_BACKGROUND_ID = 'COVER_BACKGROUND'
export const COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION_ID =
  'COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION'
export const COVER_BACK_DEFAULT_TEXT_COPYRIGHT_ID = 'COVER_BACK_DEFAULT_TEXT_COPYRIGHT'
